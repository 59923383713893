import {t} from "i18next";
import ELEMENT_TYPES from "./ElementTypes";

import coloredBlueSprite from '../Assets/ElementSprites/colored-blue.png';
import coloredRedSprite from '../Assets/ElementSprites/colored-red.png';
import coloredGreenSprite from '../Assets/ElementSprites/colored-green.png';
import coloredYellowSprite from '../Assets/ElementSprites/colored-yellow.png';
import coloredPurpleSprite from '../Assets/ElementSprites/colored-purple.png';
import contentModifierIce1Sprite from '../Assets/ElementSprites/content-modifier-ice-1.png';
import contentModifierIce2Sprite from '../Assets/ElementSprites/content-modifier-ice-2.png';
import contentModifierIce3Sprite from '../Assets/ElementSprites/content-modifier-ice-3.png';
import contentModifierLocker1Sprite from '../Assets/ElementSprites/content-modifier-locker-1.png';
import contentModifierLocker2Sprite from '../Assets/ElementSprites/content-modifier-locker-2.png';
import contentModifierLocker3Sprite from '../Assets/ElementSprites/content-modifier-locker-3.png';
import contentWall1Sprite from '../Assets/ElementSprites/content-wall-1.png';
import contentWall2Sprite from '../Assets/ElementSprites/content-wall-2.png';
import contentWall3Sprite from '../Assets/ElementSprites/content-wall-3.png';
import contentEmptySprite from '../Assets/ElementSprites/content-empty.png';
import contentStoryItemDallaHorseSprite from '../Assets/ElementSprites/content-story-item-horse.png';
import contentPowerUpBombSprite from '../Assets/ElementSprites/power-up-bomb.png';
import contentPowerUpRocketVerticalSprite from '../Assets/ElementSprites/power-up-rocket-vertical.png';
import contentPowerUpRocketHorizontalSprite from '../Assets/ElementSprites/power-up-rocket-horizontal.png';
import contentPowerUpMagicBallSprite from '../Assets/ElementSprites/power-up-magic-ball.png';
import tile1Sprite from '../Assets/ElementSprites/tile-1.png';
import tile2Sprite from '../Assets/ElementSprites/tile-2.png';
import tileModifierGrass1Sprite from '../Assets/ElementSprites/tile-modifier-grass-1.png';
import tileModifierGrass2Sprite from '../Assets/ElementSprites/tile-modifier-grass-2.png';
import tileModifierGrass3Sprite from '../Assets/ElementSprites/tile-modifier-grass-3.png';
import tileModifierSpawnerSprite from '../Assets/ElementSprites/tile-modifier-spawner.png';
import tileModifierStoryTargetSprite from '../Assets/ElementSprites/tile-modifier-story-target.png';

/**
 * README:
 * Before adding a new element, please be aware that you are align with the following naming conventions:
 * - the values are in PascalCase
 * - the keys are in camelCase
 * - when adding a new element, please also check it in the following constants:
 * --- elementsDatabase
 * --- techMapToElements
 */
export const elementsDatabase = {
  // Tiles
  'tileLight': {
    id: 'tileLight',
    title: t('Tile light'),
    sprite: tile1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE,
    techProps: {
      tileType: 'commonLight'
    }
  },
  'tileDark': {
    id: 'tileDark',
    title: t('Tile dark'),
    sprite: tile2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE,
    techProps: {
      tileType: 'commonDark'
    }
  },

  // Tile Contents
  'coloredGreen': {
    id: 'coloredGreen',
    title: t('Green ball'),
    sprite: coloredGreenSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'coloredGreen'
    }
  },
  'coloredRed': {
    id: 'coloredRed',
    title: t('Red ball'),
    sprite: coloredRedSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'coloredRed'
    }
  },
  'coloredYellow': {
    id: 'coloredYellow',
    title: t('Yellow ball'),
    sprite: coloredYellowSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'coloredYellow'
    }
  },
  'coloredBlue': {
    id: 'coloredBlue',
    title: t('Blue ball'),
    sprite: coloredBlueSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'coloredBlue'
    }
  },
  'coloredPurple': {
    id: 'coloredPurple',
    title: t('Purple ball'),
    sprite: coloredPurpleSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'coloredPurple'
    }
  },
  'wall-1': {
    id: 'wall-1',
    title: t('Wall weak'),
    sprite: contentWall1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 1
    }
  },
  'wall-2': {
    id: 'wall-2',
    title: t('Wall normal'),
    sprite: contentWall2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 2
    }
  },
  'wall-3': {
    id: 'wall-3',
    title: t('Wall strong'),
    sprite: contentWall3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'wall',
      tileContentDurability: 3
    }
  },
  'contentStoryItemDallaHorse': {
    id: 'contentStoryItemDallaHorse',
    sprite: contentStoryItemDallaHorseSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'tileContentStoryItemDallaHorse',
    }
  },
  'contentEmpty': {
    id: 'contentEmpty',
    sprite: contentEmptySprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'tileContentEmpty',
    }
  },
  'powerUpBomb': {
    id: 'powerUpBomb',
    title: t('Bomb'),
    sprite: contentPowerUpBombSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'powerUpBomb'
    }
  },
  'powerUpRocketVertical': {
    id: 'powerUpRocketVertical',
    title: t('Rocket'),
    sprite: contentPowerUpRocketVerticalSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'powerUpVerticalRocket'
    }
  },
  'powerUpRocketHorizontal': {
    id: 'powerUpRocketHorizontal',
    title: t('Rocket'),
    sprite: contentPowerUpRocketHorizontalSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'powerUpHorizontalRocket'
    }
  },
  'powerUpMagicBall': {
    id: 'powerUpMagicBall',
    title: t('Magic ball'),
    sprite: contentPowerUpMagicBallSprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    techProps: {
      tileContentType: 'powerUpMagicBall'
    }
  },

  // Content Modifiers
  'ice-1': {
    id: 'ice-1',
    sprite: contentModifierIce1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierIce',
      durability: 1
    }
  },
  'ice-2': {
    id: 'ice-2',
    sprite: contentModifierIce2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierIce',
      durability: 2
    }
  },
  'ice-3': {
    id: 'ice-3',
    sprite: contentModifierIce3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierIce',
      durability: 3
    }
  },
  'locker-1': {
    id: 'locker-1',
    sprite: contentModifierLocker1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierLocker',
      durability: 1
    }
  },
  'locker-2': {
    id: 'locker-2',
    sprite: contentModifierLocker2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierLocker',
      durability: 2
    }
  },
  'locker-3': {
    id: 'locker-3',
    sprite: contentModifierLocker3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    techProps: {
      tileContentModifierType: 'contentModifierLocker',
      durability: 3
    }
  },

  // Tile Modifiers
  'spawner': {
    id: 'spawner',
    sprite: tileModifierSpawnerSprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tileModifierSpawner',
    }
  },
  'grass-1': {
    id: 'grass-1',
    sprite: tileModifierGrass1Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tileModifierGrass',
      durability: 1,
    }
  },
  'grass-2': {
    id: 'grass-2',
    sprite: tileModifierGrass2Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tileModifierGrass',
      durability: 2,
    }
  },
  'grass-3': {
    id: 'grass-3',
    sprite: tileModifierGrass3Sprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tileModifierGrass',
      durability: 3,
    }
  },
  'tileModifierStoryTarget': {
    id: 'tileModifierStoryTarget',
    sprite: tileModifierStoryTargetSprite,
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    techProps: {
      tileModifierType: 'tileModifierStoryDestination',
    }
  }
}

export const techMapToElements = {
  'commonLight': elementsDatabase['tileLight'],
  'commonDark': elementsDatabase['tileDark'],
  'coloredGreen': elementsDatabase['coloredGreen'],
  'coloredRed': elementsDatabase['coloredRed'],
  'coloredYellow': elementsDatabase['coloredYellow'],
  'coloredBlue': elementsDatabase['coloredBlue'],
  'coloredPurple': elementsDatabase['coloredPurple'],
  'wall': elementsDatabase['wall-3'],
  'wall-1': elementsDatabase['wall-1'],
  'wall-2': elementsDatabase['wall-2'],
  'wall-3': elementsDatabase['wall-3'],
  'tileContentStoryItemDallaHorse': elementsDatabase['contentStoryItemDallaHorse'],
  'tileContentEmpty': elementsDatabase['contentEmpty'],
  'powerUpBomb': elementsDatabase['powerUpBomb'],
  'powerUpVerticalRocket': elementsDatabase['powerUpRocketVertical'],
  'powerUpHorizontalRocket': elementsDatabase['powerUpRocketHorizontal'],
  'powerUpMagicBall': elementsDatabase['powerUpMagicBall'],
  'contentModifierIce-1': elementsDatabase['ice-1'],
  'contentModifierIce-2': elementsDatabase['ice-2'],
  'contentModifierIce-3': elementsDatabase['ice-3'],
  'contentModifierLocker-1': elementsDatabase['locker-1'],
  'contentModifierLocker-2': elementsDatabase['locker-2'],
  'contentModifierLocker-3': elementsDatabase['locker-3'],
  'tileModifierSpawner': elementsDatabase['spawner'],
  'tileModifierGrass': elementsDatabase['grass-1'],
  'tileModifierGrass-1': elementsDatabase['grass-1'],
  'tileModifierGrass-2': elementsDatabase['grass-2'],
  'tileModifierGrass-3': elementsDatabase['grass-3'],
  'tileModifierStoryDestination': elementsDatabase['tileModifierStoryTarget'],
}

export const generatableElements = [
  elementsDatabase["coloredGreen"],
  elementsDatabase["coloredRed"],
  elementsDatabase["coloredBlue"],
  elementsDatabase["coloredYellow"],
  elementsDatabase["coloredPurple"],
]

export const goalableLevelElements = [
  elementsDatabase["coloredGreen"],
  elementsDatabase["coloredRed"],
  elementsDatabase["coloredBlue"],
  elementsDatabase["coloredYellow"],
  elementsDatabase["coloredPurple"],
  elementsDatabase["wall-3"],
  elementsDatabase["powerUpBomb"],
  elementsDatabase["powerUpRocketHorizontal"],
  elementsDatabase["powerUpRocketVertical"],
  elementsDatabase["powerUpMagicBall"],
  elementsDatabase['contentStoryItemDallaHorse'],
  elementsDatabase["grass-1"],
];

export const elementsTypesTabs = [
  {
    title: t('Tiles'),
    iconClass: 'bx bx-square-rounded bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE,
    elements: [
      elementsDatabase["tileLight"],
      elementsDatabase["tileDark"],
    ]
  },
  {
    title: t('Tile modifiers'),
    iconClass: 'bx bx-shape-square bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_MODIFIER,
    elements: [
      elementsDatabase["grass-1"],
      elementsDatabase["grass-2"],
      elementsDatabase["grass-3"],
      elementsDatabase["spawner"],
      elementsDatabase['tileModifierStoryTarget'],
    ]
  },
  {
    title: t('Contents'),
    iconClass: 'bx bx-circle bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT,
    elements: [
      elementsDatabase["coloredGreen"],
      elementsDatabase["coloredRed"],
      elementsDatabase["coloredBlue"],
      elementsDatabase["coloredYellow"],
      elementsDatabase["coloredPurple"],
      elementsDatabase["wall-1"],
      elementsDatabase["wall-2"],
      elementsDatabase["wall-3"],
      elementsDatabase["powerUpBomb"],
      elementsDatabase["powerUpRocketHorizontal"],
      elementsDatabase["powerUpRocketVertical"],
      elementsDatabase["powerUpMagicBall"],
      elementsDatabase['contentStoryItemDallaHorse'],
      elementsDatabase['contentEmpty']
    ]
  },
  {
    title: t('Content modifiers'),
    iconClass: 'bx bx-loader-circle bx-sm',
    type: ELEMENT_TYPES.TYPE_TILE_CONTENT_MODIFIER,
    elements: [
      elementsDatabase["ice-1"],
      elementsDatabase["ice-2"],
      elementsDatabase["ice-3"],
      elementsDatabase["locker-1"],
      elementsDatabase["locker-2"],
      elementsDatabase["locker-3"],
    ]
  },
]
export function getParamNames(func) {
  let STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
  let ARGUMENT_NAMES = /([^\s,]+)/g;

  let fnStr = func.toString().replace(STRIP_COMMENTS, '');

  let result = fnStr.slice(fnStr.indexOf('(')+1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);

  if(result === null)
    result = [];

  return result;
}

export function initHelpers() {
  Object.defineProperty(String.prototype, 'ucFirst', {
    value: function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
  });

  Object.defineProperty(String.prototype, 'lcFirst', {
    value: function() {
      return this.charAt(0).toLowerCase() + this.slice(1);
    },
    enumerable: false
  });
}